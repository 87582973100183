import { makeStyles } from "@material-ui/styles";
import React from "react";
import { FirstPage } from "./first-page/first-page";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { PageHeader } from "./sections/page-header";
import { Project } from "./sections/project";
import {
  EmptyListItem,
  ProfileForPdf,
  ProjectType,
  SkillItemType,
} from "../../types/ProfileForPdf";

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: "#14C4D8",
  },
  page: {
    height: "297mm",
  },
  fullPage: {
    paddingTop: "14.5mm",
    paddingBottom: "14.5mm",
    paddingLeft: "24mm",
    paddingRight: "24mm",
    backgroundColor: "#fff",
    display: "block",
  },
}));

const ProfilePdf: React.FC<{ PdfData: ProfileForPdf }> = ({ PdfData }) => {
  const classes = useStyles();
  console.log("rendering for ", PdfData);
  return (
    <Box display="absolute">
      <Box className={`firstPage ${classes.page}`}>
        <FirstPage
          basicInfos={PdfData?.basicInformations}
          languages={PdfData?.languages as SkillItemType[]}
          skillLevels={PdfData?.skillLevels as SkillItemType[]}
          techFrameworks={PdfData?.techFrameworks as SkillItemType[]}
          projectManagement={PdfData?.projectManagement as SkillItemType[]}
          contact={PdfData.contact}
        />
      </Box>
      <Box display="flex" className={`second-part ${classes.fullPage}`}>
        <Grid className="page-container" container>
          <Grid item xs={12}>
            <PageHeader
              basicInfos={PdfData?.basicInformations}
              contact={PdfData.contact}
            ></PageHeader>
          </Grid>
          {(PdfData?.projects?.length ?? 0) > 0 && (
            <Grid item xs={12}>
              <Box mt={4}>
                <Typography variant="h6">Projekte</Typography>
                <Divider className={classes.divider}></Divider>
                <Grid container>
                  <Grid item xs={12}>
                    {PdfData?.projects?.map(
                      (project: EmptyListItem | ProjectType, index: number) => {
                        return (
                          <Box
                            className="maybe-break- project"
                            mb={2}
                            key={`project-${index}`}
                          >
                            <Project data={project as ProjectType}></Project>
                          </Box>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          {(PdfData?.educations?.length ?? 0) > 0 && (
            <Grid item xs={12}>
              <Box mt={4}>
                <Typography variant="h6">Bildung</Typography>
                <Divider className={classes.divider}></Divider>
                <Grid container>
                  <Grid item xs={12}>
                    {PdfData?.educations?.map(
                      (education: EmptyListItem | ProjectType, index: any) => {
                        return (
                          <Box
                            className="maybe-break-"
                            mb={2}
                            key={`bildung-${index}`}
                          >
                            <Project data={education as ProjectType}></Project>
                          </Box>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default ProfilePdf;
