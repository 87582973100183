import React from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { SkillSet } from "../sections/skillSet";
import { PageHeader } from "../sections/page-header";
import {
  BasicInformationsType,
  ProfileForPdf,
  SkillItemType,
} from "../../../types/ProfileForPdf";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: "12.25mm",
    paddingRight: "22.5mm",
    paddingTop: "14.5mm",
  },
  divider: {
    backgroundColor: "#14C4D8",
  },
  title: {
    textTransform: "uppercase",
    fontSize: "2.0rem !important",
    lineHeight: 1,
  },
  basicInfosSection: {
    background: "#ededec",
  },
}));
export const RightArea: React.FC<{
  basicInfos?: BasicInformationsType;
  contact?: ProfileForPdf["contact"];
  techFrameworks?: SkillItemType[];
  projectManagement?: SkillItemType[];
}> = ({ contact, basicInfos, techFrameworks, projectManagement }) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <PageHeader basicInfos={basicInfos} contact={contact} />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" pt={7}>
            <Typography className={classes.title}>
              {basicInfos?.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" pt={4}>
            <Typography variant="subtitle2" align="justify">
              {basicInfos?.desc}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" pt={6.5}>
            <Typography variant="h6">Skillbeschreibung</Typography>
          </Box>
          <Divider className={classes.divider} variant="fullWidth" />
          {(techFrameworks?.length ?? 0) > 0 && (
            <Box display="flex" pt={3}>
              <SkillSet title="" skillsData={techFrameworks} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
