import React from "react";
import { Box } from "@material-ui/core";
import { LeftArea } from "./left-area";
import { RightArea } from "./right-area";
import { makeStyles } from "@material-ui/styles";
import {
  BasicInformationsType,
  ProfileForPdf,
  SkillItemType,
} from "../../../types/ProfileForPdf";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "72mm 138mm",
    gridGap: "0px",
  },

  leftSide: {
    background: "#ededec",
  },
  rightSide: {
    background: "#fff",
  },
}));

interface FirstPageProps {
  basicInfos?: BasicInformationsType;
  languages?: SkillItemType[];
  skillLevels?: SkillItemType[];
  techFrameworks?: SkillItemType[];
  projectManagement?: SkillItemType[];
  contact?: ProfileForPdf["contact"];
}

export const FirstPage: React.FC<FirstPageProps> = ({
  basicInfos,
  languages,
  skillLevels,
  techFrameworks,
  projectManagement,
  contact,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.leftSide}>
        <LeftArea
          basicInfos={basicInfos}
          languages={languages}
          skillLevels={skillLevels || []}
        />
      </Box>
      <Box className={classes.rightSide}>
        <RightArea
          basicInfos={basicInfos}
          techFrameworks={techFrameworks}
          projectManagement={projectManagement}
          contact={contact}
        />
      </Box>
    </Box>
  );
};
