import { ProfileFromStrapi } from "../types/ProfileFromStrapi";
import { ProfileForPdf } from "../types/ProfileForPdf";

/**
 * Helps fetch profiles from Strapi that can be rendered by the PDF generator.
 */
export class StrapiProfileFetcher {
  private STRAPI_URL =
    process.env.REACT_APP_STRAPI_URL || "http://localhost:1337";

  /**
   * Fetch a full profile including its image
   * @param profileId
   */
  async fetch(profileId: number): Promise<ProfileForPdf> {
    const response = await fetch(`${this.STRAPI_URL}/profiles/${profileId}`);
    const strapiProfile = (await response.json()) as ProfileFromStrapi | null;
    if (!strapiProfile) {
      throw new Error("Profile not found");
    }
    return this.strapiProfileToPdfProfile(strapiProfile, true);
  }

  /**
   * Fetch a list of all profiles without loading their images.
   */
  async fetchAllWithoutImage(): Promise<ProfileForPdf[]> {
    const response = await fetch(`${this.STRAPI_URL}/profiles`);
    const strapiProfiles = (await response.json()) as ProfileFromStrapi[];
    return Promise.all(
      strapiProfiles
        .sort((p1, p2) => (p1.Name ?? "").localeCompare(p2.Name ?? ""))
        .map((profile) => this.strapiProfileToPdfProfile(profile))
    );
  }

  /**
   * Transform a profile from Strapi into a profile that can be consumed by the PDF Generator.
   * @param strapiProfile
   * @param loadImage - if set to `true`, profile's image will be loaded and transformed into a base64-encodede string
   *                    that can be read by the PDF generator. Loading images will consume substantial resources and
   *                    therefore should only happen when loading a single profile for PDF rendering, but not when
   *                    loading a list of profile.s
   * @private
   */
  private async strapiProfileToPdfProfile(
    strapiProfile: ProfileFromStrapi,
    loadImage?: boolean
  ): Promise<ProfileForPdf> {
    return {
      id: strapiProfile.id,
      basicInformations: {
        name: strapiProfile.Name,
        invisibleNameAddition: strapiProfile.InvisibleNameAddition,
        avatar: {
          base64:
            loadImage && strapiProfile.Photo?.url
              ? await this.convertImgToBase64URL(
                  `${this.STRAPI_URL}${strapiProfile.Photo.url}`
                )
              : "",
        },
        daily_rate: strapiProfile.Dayrate,
        desc: strapiProfile.Summary,
        place: strapiProfile.Location,
        role: strapiProfile.Role,
        degree: {
          label: strapiProfile.Degree,
          value: strapiProfile.Degree,
        },
        availability: strapiProfile.AvailabilityFrom,
      },
      educations: strapiProfile.Education.map((e) => ({
        title: e.Title,
        desc: e.Description ?? "",
        role: "",
        start_date: e.Start,
        end_date: e.End,
      })),
      languages: strapiProfile.Languages.map((l) => ({
        label: {
          label: l.language.Name,
          value: l.language.Name,
        },
        level: l.language_proficiency.Name,
      })),
      projects: strapiProfile.Projects.map((p) => ({
        title: p.Title,
        role: p.Role,
        desc: p.Description,
        start_date: p.Start,
        end_date: p.End,
      })),
      skillLevels: strapiProfile.Skills.map((s) => ({
        level: s.skill_proficiency.Name,
        label: {
          label: s.skill.Name ?? "",
          value: s.skill.Name ?? "",
        },
      })),
      techFrameworks: strapiProfile.SkillDescriptions.map((s) => ({
        label: {
          label: s.SkillDescription,
          value: s.SkillDescription,
        },
      })),
      contact: {
        email: strapiProfile.contact?.Email,
        name: strapiProfile.contact?.Name,
      },
    };
  }

  private convertImgToBase64URL(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        let canvas: HTMLCanvasElement = document.createElement(
          "CANVAS"
        ) as HTMLCanvasElement;
        const ctx = canvas.getContext("2d");
        canvas.height = img.height;
        canvas.width = img.width;
        ctx?.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL();
        resolve(dataURL);
      };
      img.src = url;
    });
  }
}
