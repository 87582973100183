import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { BasicInformationsType } from "../../../types/ProfileForPdf";
import { format } from "date-fns";

const useStyles = makeStyles(() => ({
  root: {
    "& p": {
      fontSize: "2.8mm",
    },
    "& span": {
      fontSize: "2.8mm",
    },
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "25mm auto",
  },
  text: {
    fontSize: "3.3mm",
    wordSpacing: "3px",
  },
}));

export const BasicInfos: React.FC<{ basicInfosData?: BasicInformationsType }> =
  ({ basicInfosData }) => {
    const classes = useStyles();
    return (
      <Box className={classes.root}>
        <Box>
          <Typography variant="h6"> Basis-Daten </Typography>
        </Box>
        {basicInfosData?.name && (
          <Box className={classes.grid} mt={2}>
            <Box className={classes.text}>Name:</Box>
            <Box className={classes.text}>{basicInfosData.name}</Box>
          </Box>
        )}

        {basicInfosData?.place && (
          <Box className={classes.grid} mt={1}>
            <Box className={classes.text}>Ort:</Box>
            <Box className={classes.text}>{basicInfosData.place}</Box>
          </Box>
        )}

        {basicInfosData?.degree && (
          <Box className={classes.grid} mt={1}>
            <Box className={classes.text}>Abschluss:</Box>
            <Box className={classes.text}>{basicInfosData?.degree.label}</Box>
          </Box>
        )}

        {basicInfosData?.daily_rate && (
          <Box className={classes.grid} mt={1}>
            <Box className={classes.text}>Tagessatz:</Box>
            <Box className={classes.text}>{basicInfosData.daily_rate} €</Box>
          </Box>
        )}

        {basicInfosData?.availability && (
          <Box className={classes.grid} mt={1}>
            <Box className={classes.text}>Verfügbarkeit:</Box>
            <Box className={classes.text}>
              {format(new Date(basicInfosData.availability), "dd.MM.yyyy")}
            </Box>
          </Box>
        )}
      </Box>
    );
  };
