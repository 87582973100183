import React from "react";
import { Box, Grid } from "@material-ui/core";
import { BasicInfos } from "../sections/basic-data-area";
import { SkillLevels } from "../sections/skill-levels-area";
import { makeStyles } from "@material-ui/styles";
import { PdfAvatar } from "../sections/avatar";
import {
  BasicInformationsType,
  SkillItemType,
} from "../../../types/ProfileForPdf";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: "12mm",
  },
}));

export const LeftArea: React.FC<{
  basicInfos?: BasicInformationsType;
  languages?: SkillItemType[];
  skillLevels: SkillItemType[];
}> = ({ basicInfos, languages, skillLevels }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <PdfAvatar avatar={basicInfos?.avatar} />
        </Grid>
        <Grid item xs={12}>
          <Box>
            {basicInfos && <BasicInfos basicInfosData={basicInfos} />}

            {(languages?.length ?? 0) > 0 && (
              <Box mt={4}>
                <SkillLevels
                  title="Sprachen"
                  SkillLevelsData={languages || []}
                />
              </Box>
            )}

            {(skillLevels?.length ?? 0) > 0 && (
              <Box mt={4}>
                <SkillLevels
                  title="Skill-Level"
                  SkillLevelsData={skillLevels.slice(0, 10)}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
