import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Box } from "@material-ui/core";
import { AvatarProps } from "../../../types/ProfileForPdf";
const useStyles = makeStyles(() => ({
  root: {
    width: "42.5mm",
    height: "42.5mm",
    marginBottom: 25,
    border: "5px solid #14c4d8",
    marginLeft: "-12mm",
    marginTop: "35.25mm",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
}));

export const PdfAvatar: React.FC<{ avatar?: AvatarProps }> = ({ avatar }) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center">
      <Avatar
        className={classes.root}
        src={avatar?.croppedInBase64 ?? avatar?.base64}
      />
    </Box>
  );
};
