import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import "./App.css";
import { generateAndDownloadPdf } from "./utils/PdfGenerator";
import { ProfileForPdf } from "./types/ProfileForPdf";
import { StrapiProfileFetcher } from "./utils/StrapiProfileFetcher";
import { DocxGenerator } from "./utils/DocxGenerator";
import { logRoles } from "@testing-library/react";

const profileFetcher = new StrapiProfileFetcher();

function App() {
  const [profiles, setProfiles] = useState<ProfileForPdf[]>([]);
  const [selectedProfileId, setSelectedProfileId] = useState<number>();

  async function startDownload() {
    if (!selectedProfileId) {
      alert("No profile selected");
      return;
    }
    const profile = await profileFetcher.fetch(selectedProfileId);
    generateAndDownloadPdf(profile)();
  }
  async function startDownloadDocx() {
    if (!selectedProfileId) {
      alert("No profile selected");
      return;
    }
    const profile = await profileFetcher.fetch(selectedProfileId);
    new DocxGenerator().download(profile);
  }

  useEffect(() => {
    profileFetcher.fetchAllWithoutImage().then((profiles) => {
      setProfiles(profiles);
      setSelectedProfileId(profiles[0]?.id);
    });
  }, []);

  const handleSelectProfile: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setSelectedProfileId(+e.target.value);
  };

  return (
    <div className="App">
      <h1>Download profile</h1>
      <select onChange={handleSelectProfile} value={selectedProfileId}>
        {profiles.reverse().map((profile, i) => (
          <option key={i} value={profile.id}>
            {profile.basicInformations?.name}{" "}
            {profile.basicInformations?.invisibleNameAddition &&
              `(${profile.basicInformations.invisibleNameAddition})`}
          </option>
        ))}
      </select>
      <br />
      <button
        onClick={() => {
          startDownload();
        }}
      >
        Download (PDF)
      </button>
      <button
        onClick={() => {
          startDownloadDocx();
        }}
      >
        Download (DOCX)
      </button>
    </div>
  );
}

export default App;
