import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  BasicInformationsType,
  ProfileForPdf,
} from "../../../types/ProfileForPdf";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& p": {
      fontSize: "0.6rem !important",
    },
  },
  headerContainer: {
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  logoContainer: {
    position: "relative",
    maxWidth: "22.85mm",
    "& img": {
      maxWidth: "100%",
    },
  },
}));

export const PageHeader: React.FC<{
  basicInfos?: BasicInformationsType;
  contact?: ProfileForPdf["contact"];
}> = ({ basicInfos, contact }) => {
  const classes = useStyles();
  console.log("Rendering contact :", contact);
  return (
    <Box display="flex" className={classes.root}>
      <Box className={classes.headerContainer} display="flex">
        <Box className={classes.logoContainer}>
          <img src="/hipSquareLogo.png" alt="HipSquare Logo" />
        </Box>
        <Grid container justify="flex-end">
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography>
                HipSquare Gmbh - Zieblandstr. 1 - 80799 München
              </Typography>
            </Box>
            {contact && (
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography>
                  Kontakt: {contact.name} ({contact.email})
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
