import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { SkillItemType } from "../../../types/ProfileForPdf";

const useStyles = makeStyles(() => ({
  root: {
    "& p": {
      fontSize: "2.8mm",
    },
    "& span": {
      fontSize: "2.8mm",
    },
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "25mm auto",
  },
  text: {
    fontSize: "3.5mm",
  },
}));

export const SkillLevels: React.FC<{
  title: string;
  SkillLevelsData: SkillItemType[];
}> = ({ title, SkillLevelsData }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box>
        <Typography variant="h6"> {title} </Typography>
      </Box>
      {SkillLevelsData.map(
        (item, idx) =>
          "label" in item && (
            <Box
              className={classes.grid}
              mt={idx > 0 ? 1 : 2}
              key={`key-${item.label.value}-${item.level}`}
            >
              <Box className={classes.text}>{item.label.label}:</Box>
              {item.level && <Box className={classes.text}>{item.level}</Box>}
            </Box>
          )
      )}
    </Box>
  );
};
