import {
  AlignmentType,
  BorderStyle,
  Document,
  HeadingLevel,
  HeightRule,
  ImageRun,
  ITableBordersOptions,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";
import { ProfileForPdf, ProjectType } from "../types/ProfileForPdf";

export class DocxGenerator {
  hipsquareBlueLight = "#1bcadc";
  hipsquareBlueDark = "#0e2641";
  hipsquareBlueExtraDark = "#01030d";
  hipsquareGreyLight = "#eeeeee";
  hipsquareGreyExtraLight = "#fafafa";
  hipsquareGreyDark = "#777777";
  hipsquareGreyExtraDark = "#333333";

  font = "Open Sans Light";
  headlineColor = this.hipsquareBlueDark;
  headline2Size = "18pt";
  headline3Size = "14pt";
  headline4Size = "11pt";
  headline5Size = "10pt";
  textSize = "10pt";
  textSizeXs = "8pt";
  fontSizeContactBox = "8pt";
  leftContentCellBackground = this.hipsquareGreyExtraLight;
  fontColor = "#333333";
  spaceBeforeDocumentHeadline = 400;

  spaceBeforeHeadline = 350;
  spaceAfterHeadline = 300;
  spaceBeforeHeadline4 = 300;
  spaceAfterHeadline4 = 80;
  spaceAfterProject = 300;
  contentCellMargin = 500;
  projectTitleCellMargin = 150;

  profilePictureHeight = 210;
  profilePictureWidth = 210;

  logoHeight = 20;
  logoWidth = 80;

  private textStyle = {
    font: this.font,
    color: this.fontColor,
    size: this.textSize,
  };

  private noBorders: ITableBordersOptions = {
    left: { style: BorderStyle.NONE },
    right: { style: BorderStyle.NONE },
    top: { style: BorderStyle.NONE },
    bottom: { style: BorderStyle.NONE },
    insideHorizontal: { style: BorderStyle.NONE },
    insideVertical: { style: BorderStyle.NONE },
  };

  async generate(profile: ProfileForPdf): Promise<Blob> {
    const document = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
            },
          },
          children: [
            this.generateContainerTable(
              [
                await this.generateProfilePicture(profile),
                this.generateHeadlineOfSize(
                  "Basisdaten",
                  HeadingLevel.HEADING_4,
                  {
                    extraTopMargin: true,
                  }
                ),
                this.generateBaseData(profile),
                this.generateHeadlineOfSize("Sprachen", HeadingLevel.HEADING_4),
                this.generateLanguages(profile),
              ],
              [
                await this.generateHeaderTable(profile),
                this.generateDocumentHeadline(
                  profile.basicInformations?.name ?? ""
                ),
                ...this.generateText(profile.basicInformations?.desc ?? ""),
                this.generateHeadlineOfSize(
                  "Skillbeschreibung",
                  HeadingLevel.HEADING_2
                ),
                ...this.generateBulletList(
                  profile.techFrameworks?.map((l) => l.label.value) ?? []
                ),
                this.generateHeadlineOfSize("Projekte", HeadingLevel.HEADING_2),
                ...this.generateProjects(profile),
                this.generateHeadlineOfSize("Bildung", HeadingLevel.HEADING_2),
                ...this.generateEducation(profile),
              ]
            ),
          ],
        },
      ],
    });
    return Packer.toBlob(document);
  }

  private async generateProfilePicture(
    profile: ProfileForPdf
  ): Promise<Paragraph> {
    if (!profile.basicInformations?.avatar?.base64) {
      return new Paragraph({ children: [] });
    }

    return new Paragraph({
      children: [
        new ImageRun({
          data: profile.basicInformations.avatar.base64,
          transformation: {
            height: this.profilePictureHeight,
            width: this.profilePictureWidth,
          },
        }),
      ],
    });
  }

  private async generateHeaderTable(profile: ProfileForPdf): Promise<Table> {
    const logo = await this.generateLogo();
    return new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: this.noBorders,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.AUTO,
              },
              children: [
                new Paragraph({
                  children: [logo],
                  alignment: AlignmentType.END,
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [this.generateContactText(profile)],
                  alignment: AlignmentType.END,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  private generateContactText(profile: ProfileForPdf): TextRun {
    return new TextRun({
      text: `Ihr Kontakt: ${profile.contact?.name}, ${profile.contact?.email})`,
      ...this.textStyle,
      size: this.fontSizeContactBox,
    });
  }

  private async generateLogo(): Promise<ImageRun> {
    return new ImageRun({
      data: await this.fetchImage("/hipSquareLogo.png"),
      transformation: {
        height: this.logoHeight,
        width: this.logoWidth,
      },
    });
  }

  private fetchImage(url: string): Promise<string> {
    return fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(blob);
        });
      });
  }

  private generateContainerTable(
    leftChildren: (Table | Paragraph)[],
    rightChildren: (Table | Paragraph)[]
  ) {
    return new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: this.noBorders,
      rows: [
        new TableRow({
          height: {
            rule: HeightRule.ATLEAST,
            value: 16838,
          },
          children: [
            new TableCell({
              shading: {
                fill: this.leftContentCellBackground,
              },
              width: {
                size: 35,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                left: this.contentCellMargin,
                right: this.contentCellMargin,
                top: this.contentCellMargin,
                bottom: this.contentCellMargin,
              },

              children: leftChildren,
            }),
            new TableCell({
              margins: {
                left: this.contentCellMargin,
                right: this.contentCellMargin,
                top: this.contentCellMargin,
                bottom: this.contentCellMargin,
              },
              children: rightChildren,
            }),
          ],
        }),
      ],
    });
  }

  private generateDocumentHeadline(text: string) {
    return new Paragraph({
      children: [
        new TextRun({
          text,
          font: this.font,
          size: "40pt",
          color: this.headlineColor,
        }),
      ],
      spacing: {
        before: this.spaceBeforeDocumentHeadline,
      },
      heading: HeadingLevel.HEADING_1,
    });
  }

  private generateHeadlineOfSize(
    text: string,
    headingLevel: HeadingLevel = HeadingLevel.HEADING_2,
    options?: {
      extraTopMargin?: boolean;
      noMargin?: boolean;
      color?:string;
    }
  ) {
    let size: string;
    let spaceBeforeHeadline = this.spaceBeforeHeadline;
    let spaceAfterHeadline = this.spaceAfterHeadline;
    let bold = false;
    switch (headingLevel) {
      case HeadingLevel.HEADING_2: {
        size = this.headline2Size;
        break;
      }
      case HeadingLevel.HEADING_3: {
        size = this.headline3Size;
        break;
      }
      case HeadingLevel.HEADING_4: {
        size = this.headline4Size;
        spaceBeforeHeadline = this.spaceBeforeHeadline4;
        if (options?.extraTopMargin) {
          spaceBeforeHeadline = this.spaceBeforeHeadline * 2;
        }
        spaceAfterHeadline = this.spaceAfterHeadline4;
        bold = false;
        break;
      }
      case HeadingLevel.HEADING_5: {
        size = this.headline5Size;
        spaceBeforeHeadline = this.spaceBeforeHeadline4;
        if (options?.extraTopMargin) {
          spaceBeforeHeadline = this.spaceBeforeHeadline * 2;
        }
        spaceAfterHeadline = this.spaceAfterHeadline4;
        bold = false;
        break;
      }
      default: {
        size = this.headline2Size;
      }
    }

    const textStyle = {
      ...this.textStyle,
    }

    if (options?.color) {
      textStyle.color = options.color;
    }

    return new Paragraph({
      children: [
        new TextRun({
          text,
          ...textStyle,
          italics: false,
          bold,
          size,
        }),
      ],
      border: {
        bottom: {
          style: BorderStyle.SINGLE,
          color: this.hipsquareBlueLight,
        },
      },
      spacing: options?.noMargin
        ? {}
        : {
          before: spaceBeforeHeadline,
          after: spaceAfterHeadline,
        },
      heading: headingLevel,
    });
  }
  private generateText(
    text: string,
    options?: {
      alignment?: AlignmentType;
      size?: "xs";
      spacingBefore?: boolean;
      noMargin?: boolean;
    }
  ): Paragraph[] {
    if (!text) {
      return [new Paragraph({ children: [] })];
    }
    if (text.startsWith("- ")) {
      return this.generateBulletList(
        text
          .substring("-".length)
          .split("\n- ")
          .map((e) => e.trim()),
        options
      );
    }
    let size = this.textSize;
    if (options?.size === "xs") {
      size = this.textSizeXs;
    }
    return text.split("\n\n").map(paragraph => 
      new Paragraph({
        children: [
          new TextRun({
            text: paragraph,
            ...this.textStyle,
            size,
          }),
        ],
        spacing: options?.noMargin
          ? undefined
          : {
            after: 200,
            before: options?.spacingBefore ? 200 : undefined,
          },
        alignment: options?.alignment,
        keepNext: true,
      }),
    );
  }
  private generateBulletList(
    bullets: string[],
    options?: {
      alignment?: AlignmentType;
      size?: "xs";
      spacingBefore?: boolean;
    }
  ) {
    return [
      options?.spacingBefore
        ? new Paragraph({
          children: [],
        })
        : [],
      bullets.map(
        (bullet) =>
          new Paragraph({
            children: [
              new TextRun({
                text: bullet,
                ...this.textStyle,
              }),
            ],
            bullet: {
              level: 0,
            },
          })
      ),
    ].flatMap((p) => p);
  }

  private generateBaseData(profile: ProfileForPdf) {
    const tableContents: string[][] = [
      ["Name", profile.basicInformations?.name ?? ""],
      ["Ort", profile.basicInformations?.place ?? ""],
      ["Abschluss", profile.basicInformations?.degree?.label ?? ""],
      ["Verfügbarkeit", profile.basicInformations?.availability ?? ""],
    ];

    return this.generateTableFromStringArray(tableContents);
  }

  private generateLanguages(profile: ProfileForPdf) {
    const tableContents =
      profile.languages?.map((language) => [
        language.label.label ?? "",
        language.level ?? "",
      ]) ?? [];

    return this.generateTableFromStringArray(tableContents);
  }

  private generateTableFromStringArray(arr: string[][]) {
    return new Table({
      width: {
        size: 99,
        type: WidthType.PERCENTAGE,
      },
      borders: this.noBorders,

      rows: arr.map(
        (row) =>
          new TableRow({
            children: row.map(
              (cell, cellIndex) =>
                new TableCell({
                  margins: {
                    bottom: 60,
                    right: cellIndex === 0 ? 100 : 0,
                  },
                  width: {
                    size: Math.floor(100 / row.length),
                    type: WidthType.PERCENTAGE,
                  },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: cell || "-",
                          ...this.textStyle,
                          bold: cellIndex === 0,
                        }),
                      ],
                    }),
                  ],
                })
            ),
          })
      ),
    });
  }

  private generateProjects(profile: ProfileForPdf): Table[] {
    return (
      profile.projects?.flatMap((project) => [
        this.generateProject(project),
        new Paragraph({
          text: "",
          spacing: {
            after: this.spaceAfterProject,
          },
        }),
      ]) ?? []
    );
  }
  private generateProject(project: ProjectType): Table {
    return new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: this.noBorders,
      rows: [
        new TableRow({
          tableHeader: true,
          children: [
            new TableCell({
              shading: {
                fill: this.hipsquareGreyExtraLight,
              },
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 60,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: this.projectTitleCellMargin,
                bottom: this.projectTitleCellMargin,
                left: this.projectTitleCellMargin,
              },
              children: ([
                this.generateHeadlineOfSize(
                  `${project.title}`,
                  HeadingLevel.HEADING_4,
                  { noMargin: true }
                ),
                project.role?
                this.generateHeadlineOfSize(
                  `${project.role}`,
                  HeadingLevel.HEADING_5,
                  { noMargin: true, color: "#888888"}
                ) : undefined,
              ].filter(Boolean) as Paragraph[]),
            }),
            new TableCell({
              width: {
                size: 40,
                type: WidthType.PERCENTAGE,
              },
              shading: {
                fill: this.hipsquareGreyExtraLight,
              },
              margins: {
                right: this.projectTitleCellMargin,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: this.generateText(
                `${new Date(project.start_date).toLocaleDateString()} - ${project.end_date
                  ? new Date(project.end_date).toLocaleDateString()
                  : "heute"
                }`,
                {
                  alignment: AlignmentType.END,
                  size: "xs",
                  noMargin: true,
                }
              ),
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: this.generateText(project.desc, {
                spacingBefore: true,
              }),
              columnSpan: 2,
            }),
          ],
        }),
      ],
    });
  }

  private generateEducation(profile: ProfileForPdf): Table[] {
    return (
      profile.educations?.flatMap((educationEntry) => [
        this.generateProject(educationEntry),
        new Paragraph({
          text: "",
          spacing: {
            after: this.spaceAfterProject,
          },
        }),
      ]) ?? []
    );
  }

  async download(profile: ProfileForPdf) {
    const document = await this.generate(profile);
    saveAs(document, `HipSquare-Profil ${profile.basicInformations?.name}.docx`);
  }
}
