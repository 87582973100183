import { renderToStaticMarkup } from "react-dom/server";
import ProfilePdf from "../templates/profile/profile-pdf";
import { ProfileForPdf } from "../types/ProfileForPdf";
import { PageHeader } from "../templates/profile/sections/page-header";

const html2pdf = require("html2pdf.js");
const PX_TO_MM_MULTIPLIER = 0.264583333;
const PDF_PAGE_HEIGHT_IN_MM = 297;
const PDF_PAGE_BOTTOM_MARGIN_IN_MM = 18; // the bottom margin for a PDF-Page
const DISTANCE_FROM_HEADER_IN_MM = 14; // Distance between page header and page content
const PDF_HEADER_RIGHT_MARGIN_IN_MM = 24; // Header's right margin in mm

const PDF_OPTIONS = {
  margin: [0, 0, 0, 0],
  filename: "",
  html2canvas: { scale: 2, letterRendering: true, scrollX: 0, scrollY: 0 },
  jsPDF: {
    orientation: "p",
    unit: "mm",
    format: "a4",
    floatPrecision: "smart",
  },
};

/**
 * Generate a profile as PDF-File and trigger a download dialog for it.
 * @param profile
 */
export const generateAndDownloadPdf = (profile: ProfileForPdf) => async () => {
  const pdfHeader = getRenderedPDFHeaderInHtml(profile);
  PDF_OPTIONS.filename = [
    "HipSquare",
    profile.basicInformations?.name ?? "undefined-name",
    "Profil",
  ].join("_");

  let worker = html2pdf();
  worker.set(PDF_OPTIONS);
  await worker
    .from(renderToStaticMarkup(<ProfilePdf PdfData={profile} />))
    // render html string to container
    .toContainer()
    // get rendered container
    .get("container")
    // apply changes on container
    .then((container: HTMLElement) => {
      //get Page-Container
      const pageContainer = container.querySelector(".page-container");

      container.querySelectorAll(".maybe-break").forEach((item) => {
        applyPageBreak(
          pageContainer as Element,
          item as HTMLElement,
          pdfHeader.cloneNode(true) as HTMLDivElement
        );
      });
      return container;
    })
    .toPdf()
    .get("pdf")
    .save();
};

/**
 * Apply page-break if applicable
 * @param item
 * @param pdfHeader
 */
function applyPageBreak(
  pageContainer: Element,
  item: HTMLElement,
  pdfHeader: HTMLDivElement
) {
  // get item-offset and height in mm
  const itemOffsetInMM = item.getBoundingClientRect().top * PX_TO_MM_MULTIPLIER;
  const itemHeightInMM = item.clientHeight * PX_TO_MM_MULTIPLIER;
  // calculate page nbr.
  const page = Math.ceil(itemOffsetInMM / PDF_PAGE_HEIGHT_IN_MM);

  // check if element can be outbound from the current page
  if (
    itemOffsetInMM + itemHeightInMM >
    PDF_PAGE_HEIGHT_IN_MM * page - PDF_PAGE_BOTTOM_MARGIN_IN_MM
  ) {
    try {
      // set header css in order to get the position of the top of a new page
      pdfHeader.style.position = "absolute";
      pdfHeader.style.left = "0";
      pdfHeader.style.right = `${
        PDF_HEADER_RIGHT_MARGIN_IN_MM / PX_TO_MM_MULTIPLIER
      }px`;
      pdfHeader.style.top = `${
        (page * PDF_PAGE_HEIGHT_IN_MM) / PX_TO_MM_MULTIPLIER
      }px`;

      // insert header to the page container
      pageContainer.append(pdfHeader);
      let itemToBreak: HTMLElement = item;

      if (item.classList.contains("apply-break-on-previous")) {
        itemToBreak = item.previousElementSibling as HTMLElement;
      }

      if (item.classList.contains("apply-break-on-parent")) {
        itemToBreak = item.parentElement as HTMLElement;
      }

      if (item.classList.contains("apply-break-on-parent-parent-parent")) {
        itemToBreak = item.parentElement?.parentElement
          ?.parentElement as HTMLElement;
      }

      // calculate top margin that should be add to the element in order to push it in the right position of the next new page
      const elementToBreakTopMargin =
        page * PDF_PAGE_HEIGHT_IN_MM - // get the start position of the current page of element to break in the Window-Container
        (itemToBreak.getBoundingClientRect().top - pdfHeader.clientHeight) * // find the rest between page end and the old element position in px
          PX_TO_MM_MULTIPLIER + // convert the last calculated difference into mm // substract the rest between current-page end and the element position
        pdfHeader.clientHeight * PX_TO_MM_MULTIPLIER + // add header height to the spacer
        DISTANCE_FROM_HEADER_IN_MM; // add required distance between header and first page element to the spacer

      // move the item to break into new position adding a calculated top margin
      itemToBreak.style.marginTop = `${elementToBreakTopMargin}mm`;
    } catch (e) {
      console.log(e);
    }
  }
}

/**
 * Render the pdf-header as HTML-Element
 * @param profileData: given Profile data
 */
function getRenderedPDFHeaderInHtml(
  profileData: ProfileForPdf
): HTMLDivElement {
  const newHtmlElement: HTMLDivElement = document.createElement("div");
  newHtmlElement.classList.add("should-break");
  newHtmlElement.style.marginTop = `${14.5 / PX_TO_MM_MULTIPLIER}px`;
  newHtmlElement.style.marginBottom = `${4 * 8}px`;
  newHtmlElement.innerHTML = renderToStaticMarkup(
    <PageHeader
      basicInfos={profileData.basicInformations}
      contact={profileData.contact}
    />
  );
  return newHtmlElement;
}
