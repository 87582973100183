import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ProjectType } from "../../../types/ProfileForPdf";
import { format } from "date-fns";
import { de } from "date-fns/locale";

const useStyles = makeStyles(() => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "28mm auto",
    gridGap: "8px",
  },
  description: {
    wordWrap: "break-word",
    paddingRight: "8px",
  },
  text: {
    fontSize: "3.5mm",
  },
  title: {
    backgroundColor: "#EDEDEC",
  },
}));

export const Project: React.FC<{ data: ProjectType }> = ({ data }) => {
  const classes = useStyles();
  return (
    <Box>
      <Box mt={1.5} mb={2} p={1} className={classes.title}>
        <Typography variant="subtitle1">{data.title}</Typography>
      </Box>

      {data.start_date && (
        <Box className={`maybe-break apply-break-on-previous ${classes.grid}`}>
          <Box display="flex" justifyContent="space-between" mr={3}>
            <Typography className={classes.text}>Dauer:</Typography>
          </Box>

          <Box className={classes.text}>
            {`${format(new Date(data.start_date), "MMMM yyyy", { locale: de })}
              -
              ${
                data.end_date
                  ? format(new Date(data.end_date), "MMMM yyyy", { locale: de })
                  : "Heute"
              }
              `}
          </Box>
        </Box>
      )}

      {data.role && (
        <Box className={`maybe-break ${classes.grid}`} mt={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.text}>Rolle:</Typography>
          </Box>
          <Box className={classes.text}>{data.role}</Box>
        </Box>
      )}

      {data.desc && (
        <Box className={classes.grid} mt={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.text}>Beschreibung:</Typography>
          </Box>
          <Box display="table" className={classes.description}>
            {data.desc.split(/\n/).map(
              (part, index) =>
                part && (
                  <Box key={`part-${index}`}>
                    <Box
                      className={`maybe-break ${
                        index === 0
                          ? "apply-break-on-parent-parent-parent"
                          : "apply-break-on-parent"
                      }`}
                      display="table-row"
                      alignContent="flex-start"
                      mb={1}
                    >
                      <Box display="table-cell">-&nbsp;&nbsp;&nbsp;&nbsp;</Box>
                      <Box display="table-cell">
                        <Typography className={classes.text} align="justify">
                          {part.trim().startsWith("-")
                            ? part.trim().slice(1, part.trim().length)
                            : part.trim()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
