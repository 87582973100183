import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { SkillItemType } from "../../../types/ProfileForPdf";

const useStyles = makeStyles(() => ({
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    "& .MuiListItemIcon": {
      minWidth: 32,
    },
  },
  text: {
    fontSize: "3.5mm",
  },
}));

export const SkillSet: React.FC<{
  title: string;
  skillsData?: SkillItemType[];
}> = ({ title, skillsData }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1"> {title} </Typography>
        <Box display="table">
          {skillsData &&
            skillsData.map((item) => (
              <Box display="table-row" key={`key-${item.label.value}`}>
                <Box display="table-cell" pr={1.5}>
                  -
                </Box>
                <Box display="table-cell">
                  <Typography className={classes.text}>
                    {item.label.label}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};
